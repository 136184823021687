.contentcarousel {
  max-width: 1280px;
  overflow-x: hidden;
  margin: 0 auto;
}

.cardcarousel {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 24rem;
  position: relative;
  border: 1px solid #ccc;
}

.cardcarousel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardcarousel-footer {
    display: flex;
  }

.cardcarousel-header span {
  color: #2d3748;
}

.cardcarousel-image {
  width: 100%;
  height: 14rem;
  max-width: 100%;
  object-fit: cover;
}

.cardcarousel button {
  display: inline-block;
  background-color: #668f05;
  color: #fff;
  border: none;
  padding: 1.5em 1.6em;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: background-color 0.5s ease-out;
}

.cardcarousel button:focus {
  outline: none;
}

.cardcarousel button:hover {
  background-color: #7db105;
}

.textcarousel-info {
  padding: 1rem 1.5rem;
  margin-bottom: 2.5rem;
}

.controlscarousel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.controlscarousel button {
  width: 40px;
  height: 40px;
  border: 0;
  background-color: #668f05;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
}

.controlscarousel button:hover {
  background-color: #7db105;
}

.controlscarousel button:nth-child(1) {
  margin-right: 2rem;
}

a {
 color: white;
}


a:link {
  color: white
}

a:hover {
  color: wheat
}

a:visited {
  color: white
}

a:focus {
  color: white
}

a:hover {

}

a:active {

}