.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.videoseccion {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.videoseccion video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.videoseccion .container {
  position: relative;
  z-index: 2;
}

.videoseccion .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

/* Media Query for devices withi coarse pointers and no hover functionality */

/* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */

@media (pointer: coarse) and (hover: none) {
  .videoseccion {
    background: url('https://fundacionsanisidromicas.org/video/portada_no_video.jpg') black no-repeat center center scroll;
  }

  .videoseccion video {
    display: none;
  }
}

/*hidden input form*/
.oculto {
  display: none;
}

/*style footer*/
.Itagra {
  font-size: small;
  color: #6c4413;
}

/*style nosotros*/
.nosotros {
  padding-top: 2%;
  padding-bottom: 2%;
  color: #6c4413;
}

/*style programas*/
.programas {
  padding-top: 2%;
  padding-bottom: 2%;
}

/*style contacto*/
.contactar {
  padding-top: 2%;
  padding-bottom: 2%;
}

/*style audio*/
.audio{
  padding-top: 2%;
  padding-bottom: 2%;
}

/*formualrio*/
.form-check{
  text-align: left;
}

/*enlaces*/
a.redes{
  color:#686868;
  padding: 3px;
}
a:hover.redes{
  color:#ca7900;
  padding: 3px;
}